import axios, { AxiosResponse } from "axios";
import { API_URL, getHeaderWithAuthorization } from "./API";
import { TThunkDispatch } from "store";
import { ExamStartResponse, TicketsResponse } from "./models/ticket";
import { setTickets, startExam, UserAnswer, finishExam } from "pages";
import { setExtraTickets } from "../pages/Tests/redux";
import { setNotification } from "layout";

export const getPddTicketNumbersRequest = () => {
  return (dispatch: TThunkDispatch) => {
    axios
      .get(API_URL + "v1/pdd-ticket/numbers", {
        headers: getHeaderWithAuthorization(),
      })
      .then((response: AxiosResponse<TicketsResponse>) => {
        const { data } = response;
        dispatch(setTickets(data));
      })
      .catch((err) => {
        dispatch(
          setNotification({
            title: "Ошибка",
            message: `error code: ${err.code}, message: ${err.message}`,
            status: "error",
          })
        );
      });
  };
};

export const getQuestionsById = async (id: number, isTopic: boolean, category?: number) =>
  await axios.get(
    `${API_URL}v1/pdd-ticket/questions?${
      isTopic ? "topic_no" : "ticket_no"
    }=${id}${category && `&category_id=${category}`}`,
    {
      headers: getHeaderWithAuthorization(),
    }
  );

export const startTickerTheme = async (idTheme: number) =>
  await axios.post(
    `${API_URL}v1/pdd-ticket/start/1/${idTheme}`,
    {},
    {
      headers: getHeaderWithAuthorization(),
    }
  );

export const startTickerTest = async (id: number) =>
  await axios.post(
    `${API_URL}v1/pdd-ticket/start-test/${id}`,
    {},
    {
      headers: getHeaderWithAuthorization(),
    }
  );

export const finishTickerTest = async (id: number, answers: any[]) => {
  const answersUpdate = answers.reduce(
    (result: { question_id: number; answer_id: number }[], item) => {
      result.push({ question_id: item.questionId, answer_id: item.answerId });
      return result;
    },
    []
  );

  await axios.post(
    `${API_URL}v1/pdd-ticket/finish/${id}`,
    { answers: answersUpdate },
    {
      headers: getHeaderWithAuthorization(),
    }
  );
};

export const getExamStart = () => {
  return (dispatch: TThunkDispatch) => {
    axios
      .post(
        API_URL + `v1/pdd-ticket/start/2`,
        {},
        {
          headers: getHeaderWithAuthorization(),
        }
      )
      .then((res: AxiosResponse<ExamStartResponse>) =>
        dispatch(startExam(res.data))
      )
      .catch((err) => {
        dispatch(
          setNotification({
            title: "Ошибка",
            message: `error code: ${err.code}, message: ${err.message}`,
            status: "error",
          })
        );
      });
  };
};

export const getExtraTickets = (id: number) => {
  return (dispatch: TThunkDispatch) => {
    axios
      .get(API_URL + `v1/pdd-ticket/extra/${id}`, {
        headers: getHeaderWithAuthorization(),
      })
      .then((res) => {
        dispatch(setExtraTickets(res.data));
      })
      .catch((err) => {
        dispatch(
          setNotification({
            title: "Ошибка",
            message: `error code: ${err.code}, message: ${err.message}`,
            status: "error",
          })
        );
      });
  };
};

export const getExamFinish = ({
  id,
  answers,
}: {
  id: number;
  answers: UserAnswer[];
}) => {
  const answersUpdate = answers.reduce(
    (result: { question_id: number; answer_id: number }[], item) => {
      result.push({ question_id: item.questionId, answer_id: item.answerId });
      return result;
    },
    []
  );
  return (dispatch: TThunkDispatch) => {
    axios
      .post(
        API_URL + `v1/pdd-ticket/finish/${id}`,
        {
          answers: answersUpdate,
        },
        {
          headers: getHeaderWithAuthorization(),
        }
      )
      .then((res) => {
        dispatch(finishExam({ id, passed: res.data.result.passed }));
      })
      .catch((err) => {
        dispatch(
          setNotification({
            title: "Ошибка",
            message: `error code: ${err.code}, message: ${err.message}`,
            status: "error",
          })
        );
      });
  };
};
