import { useState, useMemo, useEffect } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import type { QuestionsResponse } from "api/models/ticket";
import { getTickets } from "./redux";
import queryString from "query-string";
import { getQuestionsById } from "../../api/PddTicketAPI";

type LocationExam = {
  ticketQueryId: number | null;
  lessonQueryId: number | null;
  questionQueryId: number | string | null;
};

const useQuestionsFromQuery = (category?: number) => {
  const [list, setList] = useState<QuestionsResponse>([]);

  const location = useLocation();
  const ticketList = useSelector(getTickets);

  const { ticketQueryId, lessonQueryId, questionQueryId } =
    useMemo((): LocationExam => {
      const params = queryString.parse(location.search);
      const result: LocationExam = {
        lessonQueryId: null,
        ticketQueryId: null,
        questionQueryId: null,
      };
      if (params.ticket) {
        // @ts-ignore
        result.ticketQueryId = params.ticket.match(/\d+/)[0];
      }
      if (params.lesson) {
        // @ts-ignore
        result.lessonQueryId = params.lesson.match(/\d+/)[0];
      }
      if (params.question === "all") {
        result.questionQueryId = params.question;
      } else if (!isNaN(Number(params.question))) {
        result.questionQueryId = Number(params.question);
      }

      return result;
    }, [location]);

  useEffect(() => {
    const getQuestions = async (id: number, isTopic: boolean, category?: number) => {
      try {
        const { data } = await getQuestionsById(id, isTopic, category);

        setList(data);
      } catch (e) {
        console.log(e);
      }
    };

    if (ticketList.length && (ticketQueryId || lessonQueryId)) {
      if (ticketQueryId) {
        getQuestions(ticketQueryId, false, category);
      } else if (lessonQueryId) {
        getQuestions(lessonQueryId, true, category);
      } else {
        setList([]);
      }
    }
  }, [ticketList, ticketQueryId, questionQueryId]);

  return list;
};

const useGetQuery = () => {
  const location = useLocation();
  return queryString.parse(location.search);
};

export { useQuestionsFromQuery, useGetQuery };
